import React from "react"
import styled from "styled-components"

import SEO from "../../components/seo"
import PageWrapper from "../../styles/PageWrapper"
import Row from "../../styles/Row"
import InfoTable from "./InfoTable"

const PatentsPageWrapper = styled(PageWrapper)`
  padding-top: 5rem;

  color: ${props => props.theme.color.primary};
`
const NarrowRow = styled(Row)`
  margin-bottom: 3rem;

  text-align: center;

  a {
    text-decoration: underline;
  }
`

const PatentsPage = () => (
  <PatentsPageWrapper>
    <SEO title="Patents" />
    <NarrowRow>
      <h1>Patents</h1>

      <InfoTable />
    </NarrowRow>
  </PatentsPageWrapper>
)

export default PatentsPage
